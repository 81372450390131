import React from 'react';
import { Image } from 'antd';
import logo from '../Assets/logo.png';
import bgMedia from '../Assets/logo.png'
import { ThreeDots } from 'react-loader-spinner';

const CustomLazyLoading = () => {
    return (
        <div style={{ height: '100vh',  display:"flex",justifyContent:"center",alignItems:"center"}}>
            <div>
                <Image preview={false} src={logo} height={140} width={140} />
                <div  className='mx-4'>
                <ThreeDots
                    visible={true}
                    height="80"
                    width="80"
                    color="#8B0000"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
                </div>
            </div>
        </div>
    )
}

export default CustomLazyLoading